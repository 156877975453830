import React from "react";
import {Link} from "gatsby";
import {GatsbySeo} from "gatsby-plugin-next-seo/src/meta/gatsby-seo";

import Footer from "../components/Layout/Footer/Footer";
import Layout from "../components/Layout/layout";
import Menu from "../components/Layout/Menu/Menu";
import SchemaOrg from "../components/SEO/SchemaOrg";

import "./index.scss";

export default function IndexPage() {
    return <Layout>
        <GatsbySeo
            title='Mechanika - Geometria - Diagnostyka'
            description='Specjalizujemy się we wszystkich markach samochodów. Działamy na terenie Trzebini.'
            canonical='https://serwis-olaszewski.pl'
            openGraph={{
                title: 'Mechanika pojazdowa - Geometria kół - Diagnostyka samochodowa | Auto Serwis Olaszewski',
                url: 'https://serwis-olaszewski.pl',
                description: 'Specjalizujemy się we wszystkich markach samochodów. Działamy na terenie Trzebini.'
            }}
            twitter={{
                title: 'Mechanika pojazdowa - Geometria kół - Diagnostyka samochodowa | Auto Serwis Olaszewski',
                description: 'Specjalizujemy się we wszystkich markach samochodów. Działamy na terenie Trzebini.'
            }}
        />

        <SchemaOrg/>

        <Menu/>

        <main>
            <section className="mainPage">
                <div className="overlay">
                    <div className="motto p-4 mt-6">
                        <h1 className="title is-1 has-text-centered">Auto Serwis Olaszewski</h1>
                        <h2 className="title is-4 is-size-7-mobile has-text-centered">NAPRAWY &#8226; REGULACJE &#8226; WYMIANY</h2>
                    </div>

                    <p className="has-text-centered">
                        <Link to="/zakres-uslug" className="button is-large is-dark">Nasze usługi &#187;</Link>
                    </p>
                </div>
            </section>

            <section className="has-background-info pt-6 pb-6">
                <div className="container has-text-centered">
                    <h1 className="title has-text-dark">Ponad 30 lat doświadczenia w branży motoryzacyjnej!</h1>
                    <h2 className="subtitle has-text-dark mb-4">
                        Jeżeli szukasz profesjonalnego i zaufanego warsztatu samochodowego to dobrze trafiłeś!
                    </h2>

                    <div>
                        Działamy na terenie Trzebini, zdobywając przez ten czas zaufanie Naszych klientów.
                        <br/>
                        Oferujemy <Link to="/zakres-uslug">usługi</Link> w oparciu o najnowsze trendy w motoryzacji oraz diagnozowaniu usterek wszelkich marek aut,
                        <br/>
                        wykorzystując do tego wiedzę zdobywaną latami.
                    </div>
                </div>
            </section>
        </main>

        <Footer/>
    </Layout>
}
